.root {
  padding: 16px;
  border: 1px solid #e3e5e5;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &.active {
    border: 1px solid #6690ff;
  }

  .header {
    display: flex;
    flex-direction: row;

    gap: 12px;
    align-items: center;

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #090a0a;
    }

    .subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #72777a;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .item {
      display: flex;
      align-items: center;
      gap: 4px;

      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #202325;
    }
  }
}
