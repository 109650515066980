.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .label {
    color: #b8b8b8;
    &.active {
      color: #3366ff;
    }
  }
}
