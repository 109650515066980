.root {
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex-grow: 1;
    max-height: calc(100% - 52px);
  }

  .content {
    flex-grow: 1;
    overflow: scroll;

    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .itemContent {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .timeLabel {
        font-size: 12px;
      }
      .time {
      }
    }
  }

  .divider {
    border-bottom: 1px solid #f2f4f5;
    height: 1px;
    width: 100%;
  }
}
