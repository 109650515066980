.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  .header {
    margin-bottom: 24px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 0;

    .content {
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      gap: 8px;

      .disclaimer {
        font-size: 14px;
        color: #72777a;
      }

      .error {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #d3180c;

        margin-top: 8px;
      }
    }

    .buttonsWrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    gap: 16px;

    margin-bottom: 32px;

    text-decoration: none;
    color: #090a0a;

    &:visited {
      color: #090a0a;
    }

    .logo {
      height: 50px;
    }

    .dioTexts {
      display: flex;
      flex-direction: column;
      gap: 4px;

      h1 {
        margin: 0;
        font-size: 24px;
        line-height: 1;
        font-weight: 800;
        color: #090a0a;
      }
      p {
        font-size: 14px;
        margin: 0;
        line-height: 1;
        color: #090a0a;
      }
    }
  }
}
