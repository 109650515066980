.root {
  .content {
    flex-grow: 1;

    .button {
      margin-top: 32px;
      width: 100%;
    }
    .tokensList {
      .tokensListTitle {
        margin-bottom: 16px;
      }
      .accountsList {
        display: flex;
        flex-direction: column;

        align-items: flex-start;
        justify-content: flex-start;

        gap: 4px;

        .accountItem {
          width: 100%;
          padding: 8px 16px;

          border: 1px solid rgb(235, 235, 235);
          border-radius: 4px;

          display: flex;
          align-items: center;

          .right {
            flex: 1;

            .title {
              font-size: 16px;
              margin: 0;
            }
            .name {
              font-size: 14px;
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
}
