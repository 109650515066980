.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  .header {
    margin-bottom: 24px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 24px;

    .content {
      flex-grow: 1;
    }

    .buttonsWrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
