.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  padding: 12px 0;

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
      color: #090a0a;
    }
    .subtitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      color: #72777a;
    }
  }
}
