.root {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;

  border-radius: 100px;

  user-select: none;

  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  .label {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }

  &.white {
    padding: 16px 32px;
    background-color: white;

    &:active {
      background-color: rgb(216, 216, 216);
    }

    .label {
      color: #3366ff;
    }
  }

  &.primary {
    padding: 16px 32px;
    background-color: #3366ff;
    border: 1px solid #3366ff;
    &:active {
      background-color: #2f58d3;
    }

    &.disabled {
      background-color: #e3e5e5;
      border: 1px solid #e3e5e5;
      .label {
        color: #979c9e;
      }
    }

    .label {
      color: white;
    }
  }
  &.secondary {
    padding: 16px 32px;
    background-color: #dfeaff;
    border: 1px solid #dfeaff;
    &:active {
      background-color: #b7c7e4;
    }

    &.disabled {
      background-color: #e3e5e5;
      border: 1px solid #e3e5e5;
      .label {
        color: #979c9e;
      }
    }

    .label {
      color: #3366ff;
    }
  }
  &.outlined {
    padding: 16px 32px;
    border: 1px solid #3366ff;
    &:active {
      background-color: #dcdcdc;
    }

    &.disabled {
      border: 1px solid #979c9e;
      .label {
        color: #979c9e;
      }
    }

    .label {
      color: #3366ff;
    }

    &.colorError {
      border-color: #ff5247;
      .label {
        color: #ff5247;
      }
    }
  }

  &.text {
    .label {
      color: #3366ff;
    }

    &.disabled {
      .label {
        color: #979c9e;
      }
    }

    &.colorError {
      .label {
        color: #ff5247;
      }
    }
    &.colorWhite {
      .label {
        color: white;
      }
    }
  }
}
