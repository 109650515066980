.root {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .events {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
