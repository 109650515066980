.root {
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex-grow: 1;
    max-height: calc(100% - 52px);
  }

  .content {
    flex-grow: 1;
    overflow: scroll;

    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
