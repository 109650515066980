.root {
  .input {
    border: 1px solid #e3e5e6;
    border-radius: 8px;

    width: 100%;
    padding: 16px;

    font-weight: 400;
    font-size: 16px;
    line-height: 16px;

    outline: none;

    &::placeholder {
      color: #72777a;
    }

    &.errorInput {
      border: 1px solid #d3180c;
    }
  }

  .helper {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #72777a;

    margin-top: 8px;
  }
  .error {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #d3180c;

    margin-top: 8px;
  }
}
