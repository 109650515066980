.root {
  padding-bottom: 0;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: scroll;
  justify-content: space-between;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow: scroll;
    flex-grow: 1;
    padding-bottom: 20px;

    .emptyWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;

      padding: 20px 0;
    }
  }

  .header {
    margin-bottom: 16px;
  }
}
