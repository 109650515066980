.root {
  margin: 0;

  height: 100%;
  width: 100%;
  background: url(../../assets/login-bg.jpg);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;
  gap: 24px;

  align-items: center;
  justify-content: center;

  .title {
    margin: 0;
    color: white;
    max-width: 90%;
    text-align: center;
  }
}
