.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 12px;

  cursor: pointer;
  &.clickDisabled {
    cursor: default;
  }

  cursor: pointer;

  .dayTitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #6c7072;
  }

  .dayDate {
    width: 40px;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #090a0a;

    span {
      width: 100%;
    }
    &.active {
      background: #3366ff;
      border-radius: 80px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #ffffff;
    }

    &.disabled {
      color: #bababa;
    }
  }
}
