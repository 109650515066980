.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  .wrapper {
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    padding-bottom: 24px;

    .header {
      margin-bottom: 24px;
    }

    .content {
      flex-grow: 1;
    }
  }
}
