.root {
  height: 100%;
  display: flex;
  flex-direction: column;

  padding-top: 20px;

  .content {
    flex-grow: 1;
  }

  .item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    cursor: pointer;

    .itemLabel {
      color: #ff5247;
      font-weight: 500;
    }
    svg {
      position: relative;
      top: 1px;
    }
  }
}
