.root {
  height: 100%;
  display: flex;
  flex-direction: column;

  padding-top: 20px;

  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    gap: 16px;

    margin-bottom: 32px;

    text-decoration: none;
    color: #090a0a;

    &:visited {
      color: #090a0a;
    }

    .logo {
      height: 50px;
    }

    .dioTexts {
      display: flex;
      flex-direction: column;
      gap: 4px;

      h1 {
        margin: 0;
        font-size: 24px;
        line-height: 1;
        font-weight: 800;
        color: #090a0a;
      }
      p {
        font-size: 14px;
        margin: 0;
        line-height: 1;
        color: #090a0a;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 32px;

    margin-top: 32px;
    .settings {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .settingsItem {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }
    }

    .divider {
      height: 1px;
      width: 100%;
      background-color: #ececec;
    }
  }
}
.snackbar {
  position: absolute;

  left: 50%;
  width: calc(100vw - 32px);
  background-color: #23c16b;

  border-radius: 8px;

  transform: translateX(-50%);

  padding: 16px 32px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &.active {
    animation: snackbar-in 0.3s ease both;
  }
  &.hide {
    animation: snackbar-out 0.3s ease both;
  }

  span {
    color: white;
  }
  .action {
    font-weight: 700;
  }
}

@keyframes snackbar-in {
  from {
    bottom: -70px;
  }

  to {
    bottom: 16px;
  }
}
@keyframes snackbar-out {
  from {
    bottom: 16px;
  }

  to {
    bottom: -70px;
  }
}
