.root {
  height: 100%;
  display: flex;
  flex-direction: column;

  padding-top: 20px;

  .content {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    gap: 32px;

    canvas {
      background-color: #f7f9fa;
      border: 1px solid #f7f9fa;
      border-radius: 8px;
    }

    .actions {
      display: flex;
      flex-direction: row;
      gap: 16px;
      & > div {
        width: 100%;
      }
    }

    .error {
      text-align: center;
      margin: 0;
      color: #ff6d6d;
    }
  }

  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    gap: 16px;

    margin-bottom: 32px;

    text-decoration: none;
    color: #090a0a;

    &:visited {
      color: #090a0a;
    }

    .logo {
      height: 50px;
    }

    .dioTexts {
      display: flex;
      flex-direction: column;
      gap: 4px;

      h1 {
        margin: 0;
        font-size: 24px;
        line-height: 1;
        font-weight: 800;
        color: #090a0a;
      }
      p {
        font-size: 14px;
        margin: 0;
        line-height: 1;
        color: #090a0a;
      }
    }
  }
}
