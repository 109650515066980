.root {
  height: 100%;

  .settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    gap: 16px;
  }
}
