.root {
  padding: 16px 16px 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .action {
    min-width: 20px;
  }

  .title {
    user-select: none;
  }
}
