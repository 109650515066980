.root {
  display: flex;
  align-items: flex-start;
  width: 100%;

  gap: 16px;

  cursor: pointer;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;

    .title {
      font-size: 16px;
      margin: 0;
    }
    .subtitle {
      font-size: 14px;
      margin: 0;
      color: #808080;
    }
  }

  .icon {
    position: relative;
    top: 4px;
  }
}
